<template>
  <div class="mi">
    <div class="homeDownload-list" v-if="title!='联系我们'">
      <div class="homeDownload-list-right">
        <div class="homeDownload-list-right-item" @touchstart="downloadClick(item.title)" v-for="(item,index) in downloadList" :key="index">
          <img :src="item.img" alt="">
          <p class="title">{{ item.title }}</p>
        </div>
      </div>
    </div>
    <div class="backTop" @touchstart="backTop" v-if="title!='联系我们'">
      <img src="@/assets/Mi/backTop.png" alt="" srcset="">
    </div>
    <div class="wxPop">
      <el-dialog :visible.sync="wxPopShow" :before-close="wxPopClose">
        <img src="@/assets/Mi/del.png" alt="" srcset="" class="wxPop-del" @click="wxPopClose">
        <img :src="codeUrl" alt="" srcset="" id="imageWrapper" ref="imageWrapper" crossorigin="anonymous">
        <div class="openWx" @touchstart="downloadIamge">
          <p>保存二维码</p>
        </div>
      </el-dialog>
    </div>
    <div class="mnavBox">
      <div class="mnav">
        <img src="@/assets/Mi/logo.png" alt="" srcset="" class="mnav-left" @touchstart="openHome">
        <img src="@/assets/Mi/menu.png" alt="" srcset="" class="mnav-menu" @touchstart="openMenu" v-if="!menuShow">
        <img src="@/assets/Mi/close.png" alt="" srcset="" class="mnav-close" @touchstart="openMenu" v-else>
      </div>
    </div>
    <div class="downMenu-mask" v-if="menuShow" @touchmove.prevent >
      <!-- <router-link :to="{name:item.name}" v-for="item in navList" :key="item.title"> -->
        <div class="downMenu">
          <div class="downMenu-list" @touchstart="navChange(item.title,item.name)" v-for="item in navList" :key="item.title" :style="{marginBottom:item.title =='开发案例'&&caseListShow?'20px':'34px'}">
        <div class="downMenu-list-top">
          <p :style="{  color:title==item.title?'#006BF5':'#333333',}">{{ item.title }}</p>
          <img src="@/assets/Mi/down.png" alt="" srcset="" v-if="item.title =='开发案例'&&!caseListShow&&title!='开发案例'" @touchstart.stop="showCaseList">
          <img src="@/assets/Mi/up.png" alt="" srcset="" v-if="item.title =='开发案例'&&caseListShow&&title!='开发案例'" @touchstart.stop="showCaseList">
          <img src="@/assets/Mi/blueDown.png" alt="" srcset="" v-if="item.title =='开发案例'&&!caseListShow&&title=='开发案例'" @touchstart.stop="showCaseList">
          <img src="@/assets/Mi/blueUp.png" alt="" srcset="" v-if="item.title =='开发案例'&&caseListShow&&title=='开发案例'" @touchstart.stop="showCaseList">
        </div>
        <div class="downMenu-caseList" v-if="item.title =='开发案例'&&caseListShow">
          <div v-for="item in caseList" :key="item.title" @touchstart.stop="casePopClick(item.name)">
            <p :style="{color:title=='开发案例'?'#006BF5':'#333333'}">{{ item.title  }}</p>
          </div>
        </div>
      </div>
        </div>
      
      <!-- </router-link> -->
    </div>
    <router-view class="paddTop" :casePopName="casePopName" @casePopNameChange="casePopNameChange" :navTitle="title" @navTitleChange1="navChange('开发案例','mCase')" @navTitleChange="navChange('求职定制','mJob')"></router-view>
    <div class="homeSolve" id="message" v-if="title!='联系我们'">
      <div class="homeSolve-content">
        <div class="homeSolve-content-top">
          <p>提交需求获取解决方案</p>
          <p>欢迎咨询，提出您的需求，客服将尽心为您解答</p>
        </div>
        <div class="homeSolve-content-center">
          <div class="homeSolve-content-center-top">
            <span>*</span>
            <p>您的姓名</p>
          </div>
          <input type="text" placeholder="请填写您的姓名" v-model="queryParams.name">
          <div class="homeSolve-content-center-top">
            <span>*</span>
            <p>您的联系方式（已加密）</p>
          </div>
          <input type="text" placeholder="请填写您的手机号/微信号" v-model="queryParams.phone">
          <div class="homeSolve-content-center-top">
            <span>*</span>
            <p>您的需求</p>
          </div>
          <textarea name="" id="" placeholder="请描述一下您的需求" v-model="queryParams.demandDescribe"></textarea>
        </div>
        <div class="homeSolve-content-bottom">
          <button @touchstart="messageLeave">提交</button>
        </div>
      </div>
    </div>
    <div class="miFooter">
      <!-- <router-link :to="{name:item.name}" v-for="item in navList" :key="item.title"> -->
      <div class="miFooter-top">
        <div class="miFooter-top-item" @click="navChange(item.title,item.name)" v-for="item in navList" :key="item.title">
          <p>{{ item.title }}</p>
        </div>
      </div>
      <!-- </router-link> -->
      <div class="miFooter-line"></div>
      <div class="miFooter-list">
        <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">闽 ICP 备2022010263号</a>
        <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">闽公网安备35021102001747</a>
        <a @touchstart="open">增值电信业务经营许可证：闽 B2-20221070</a>
        <a>版权所有 © 千述信息科技（厦门）有限公司</a>
      </div>
    </div>
  </div>
</template>
<script>
import { messageLeave } from '@/api/index.js'
import imageUrl from "@/assets/imageUrl.png"
import html2canvas from 'html2canvas'
export default {
  data() {
    return {
      queryParams: {
        demandDescribe: '',
        name: '',
        phone: '',
      },
      wxCode: '8008208820',
      wxPopShow: false,
      title: '首页',
      menuShow: false,
      caseListShow: false,
      casePopName: '',
      navList: [
        {
          title: '首页',
          name: 'mHome'
        },
        {
          title: '开发案例',
          name: 'mCase'
        },
        {
          title: '求职定制',
          name: 'mJob'
        },
        {
          title: '关于我们',
          name: 'mAbout'
        },
        {
          title: '联系我们',
          name: 'mContact'
        },
      ],
      caseList: [
        {
          title: '会员营销系统',
          name: 'member'
        },
        {
          title: '数学化报修系统',
          name: 'digitization'
        },
        {
          title: '商城小程序',
          name: 'shopping'
        },
        {
          title: '家政上门服务小程序',
          name: 'housekeeping'
        },
        {
          title: '无人值守预约小程序',
          name: 'unmanned'
        },
        {
          title: '蛋糕预约小程序',
          name: 'cakeSubscribe'
        },
        {
          title: '线上云酒馆',
          name: 'cloudTavern'
        },
        {
          title: 'CRM系统',
          name: 'crm'
        },
      ],
      downloadList: [
        // { img: require('../../assets/Mi/home/1.png'), title: '电话' },
        { img: require('../../assets/Mi/home/2.png'), title: '微信' },
        { img: require('../../assets/Mi/home/3.png'), title: '留言' },
      ],
      codeList: [
        { img: require('../../assets/Mi/code1.png') },
        { img: require('../../assets/Mi/code2.png') },
        { img: require('../../assets/Mi/code3.png') },
        { img: require('../../assets/Mi/code4.png') },
      ],
      codeUrl: '',
    }
  },
  methods: {
    backTop() {
      window.scrollTo(0, 0);
    },
    downloadIamge() {
      html2canvas(this.$refs.imageWrapper, {
        // 解决图片跨域的配置，这里还需要后台的配合！后台不配合仅这里配置是不够的
        useCORS: true,
      }).then((canvas) => {
        let saveUrl = canvas.toDataURL('image/png')
        let aLink = document.createElement('a')
        let blob = this.base64ToBlob(saveUrl)
        let evt = document.createEvent('HTMLEvents')
        evt.initEvent('click', true, true)
        aLink.download = '二维码.jpg'
        aLink.href = URL.createObjectURL(blob)
        aLink.click()
      });
    },
    //这里把图片转base64
    base64ToBlob(code) {
      let parts = code.split(';base64,')
      let contentType = parts[0].split(':')[1]
      let raw = window.atob(parts[1])
      let rawLength = raw.length
      let uInt8Array = new Uint8Array(rawLength)
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
      }
      return new Blob([uInt8Array], { type: contentType })
    },
    casePopClick(name) {
      this.menuShow = false
      this.caseListShow = false
      this.casePopName = name
      if (this.title != '开发案例') {
        this.title = '开发案例'
        this.$router.push({ name: 'mCase' })
      }
    },
    open() {
      const image = new Image()
      image.src = imageUrl
      image.onload = () => {
        image.style.margin = "0 auto"
        image.style.display = "block"
        const newWin = window.open("", "_blank")
        newWin.document.write(image.outerHTML)
      }
    },
    messageLeave() {
      messageLeave({
        demandDescribe: this.queryParams.demandDescribe,
        name: this.queryParams.name,
        phone: this.queryParams.phone,
      }).then(res => {
        if (res.code == 200) {
          this.queryParams.demandDescribe = ''
          this.queryParams.name = ''
          this.queryParams.phone = ''
          this.$message({
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message.error('提交失败');
        }

      })
    },
    casePopNameChange(name){
      this.casePopName=name
    },
    navChange(title, name) {
      this.menuShow = false
      this.$router.push({ name: name })
      this.title = title
      if(this.title=='开发案例'){
        this.casePopName='top'
      }
      window.scrollTo(0, 0);
    },
    openHome() {
      this.$router.push('/mHome')
    },
    openMenu() {
      this.menuShow = !this.menuShow
    },
    showCaseList() {
      this.caseListShow = !this.caseListShow
    },
    wxPopClose() {
      this.wxPopShow = false
    },
    downloadClick(title) {
      if (title == '微信') {
        var input = document.createElement("input"); // 创建input对象
        input.value = this.wxCode; // 设置复制内容
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(input); // 删除临时实例
        this.wxPopShow = true
        this.codeUrl = this.codeList[Math.round(Math.random() * 3)].img
      }
      if (title == '留言') {
        document.querySelector('#message').scrollIntoView({ block: "start" })
      }
    }
  }
}
</script>
<style lang="scss">
.homeSolve,#message{
  box-shadow: none !important;
}
input,
textarea {
  outline: none;
  border: none;
}
a {
  text-decoration: none;
}
a:hover,
a:active,
a:visited,
a:link,
a:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
  background: none;
  text-decoration: none;
}
.mi {
  position: relative;
  .wxPop {
    .el-dialog {
      width: 317px;
      height: 520px;
      border-radius: 20px;
      background-color: transparent;
      box-shadow: none;
      .el-dialog__headerbtn {
        display: none;
      }
      .el-dialog__header {
        display: none;
      }
      .el-dialog__body {
        padding: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .el-dialog__headerbtn {
        top: 15px;
        right: 15px;
      }
      .wxPop-del {
        width: 12px;
        height: 12px;
        position: absolute;
      }
      img {
        width: 317px;
        height: 471px;
        top: 15px;
        right: 15px;
      }
      // p {
      //   font-size: 18px;
      //   font-weight: bold;
      //   margin-bottom: 10px;
      // }
      // span {
      //   color: #666666;
      //   font-size: 14px;
      //   font-family: SourceHanSansCN-Medium;
      //   margin-bottom: 10px;
      // }
      .openWx {
        margin-top: 14px;
        width: 100%;
        height: 35px;
        background-color: #006bf5;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          color: #fff;
          font-family: SourceHanSansCN-Medium;
          font-size: 14px;
          letter-spacing: 1px;
        }
      }
    }
  }
  .homeSolve {
    margin-top: 35px;
    padding-bottom: 30.5px;
    .homeSolve-content {
      // width: 1100px;
      // box-shadow: 0px 0px 10px 6px rgba($color: #909090, $alpha: 0.16);
      .homeSolve-content-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        p:first-child {
          color: #000000;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 5px;
        }
        p:last-child {
          color: #000000;
          font-size: 12px;
          zoom: 0.83;
        }
      }
      .homeSolve-content-center {
        display: flex;
        flex-direction: column;
        padding-left: 15px;
        padding-right: 15px;
        .homeSolve-content-center-top {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          margin-top: 19px;
          span {
            color: #ff2600;
            margin-right: 5px;
          }
          p {
            font-size: 14px;
          }
        }
        input {
          height: 32px;
          // border: 2px solid #eeeeee;
          border-radius: 5px;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 14px;
          background-color: #f4f4f4;
        }
        textarea {
          background-color: #f4f4f4;
          border-radius: 10px;
          width: 100%;
          height: 150px;
          // border: 2px solid #eeeeee;
          font-size: 14px;
          padding: 10px;
          box-sizing: border-box;
          resize: none;
        }
      }
      .homeSolve-content-bottom {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        button {
          width: 345px;
          height: 37px;
          background-color: #006bf5;
          border-radius: 19px;
          border: none;
          color: #ffffff;
          font-size: 14px;
          font-family: SourceHanSansCN-Medium;
        }
      }
    }
  }
  .backTop {
    margin-right: 20px;
    position: fixed;
    right: 0px;
    z-index: 1000;
    top: 430px;
    border-radius: 50%;
    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
    box-shadow: 0px 4px 13px 3px rgba(0, 107, 245, 0.18);
    }
  }
  .homeDownload-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
    position: fixed;
    right: 0px;
    z-index: 1000;

    .homeDownload-list-right {
      position: fixed;
    top: 285px !important;
      background-color: #fff;
      width: 35px;
      // height: 150px;
      height: 120.7px;
      border-radius: 18px;
      // margin-left: 20px;
      margin-top: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 4px 13px 3px rgba(0, 107, 245, 0.18);

      .homeDownload-list-right-item:first-child {
        margin-top: 10px;
      }
      .homeDownload-list-right-item:last-child {
        margin-bottom: 10px;
      }
      .homeDownload-list-right-item {
        border-radius: 15px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          width: 22px;
          height: 22px;
        }
        .title {
          font-family: SourceHanSansCN-Medium;
          font-size: 12px;
          color: #006bf5;
          zoom: 0.83;
          margin-top: 5px;
          // font-weight: bold;
        }
      }
    }
  }
  .paddTop {
    padding-top: 60px;
  }
  .mnavBox {
    width: 100%;
    height: 60px;
    background-color: #fff;
    z-index: 1200;
    position: fixed;
    .mnav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-left: 15px;
      padding-right: 20px;
      box-sizing: border-box;
      height: 60px;
      position: fixed;
      .mnav-left {
        width: 103px;
        height: 28px;
      }
      .mnav-menu {
        width: 19px;
        height: 19px;
      }
      .mnav-close {
        width: 15px;
        height: 15px;
      }
    }
  }
  .downMenu-mask{
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    position: fixed;
    top: 60px;
    display: flex;
    justify-content: flex-end;
    z-index: 2005;
  }
  .downMenu {
    // z-index: 1200;
    // padding-bottom: 12px;
    width: 245px;
    height: 100%;
    background-color: #fff;
    // background-color: #fff;
    // padding-left: 15px;
    // padding-right: 15px;
    // box-sizing: border-box;
    border-top: 1px solid #EEEEEE;
   
    // // height: 175px;
    // border-top: 1px solid #eeeeee;
    .downMenu-list:first-child{
      margin-top: 39px;
    }
    .downMenu-list {
      padding-right: 96px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      margin-bottom: 34px;
      .downMenu-list-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      p {
        font-size: 15px;
        color: #333333;
        font-family: SourceHanSansCN-Medium;
      }
      img {
        width: 10px;
        height: 6px;
        position: absolute;
        right: 21px;
      }
    }
    .downMenu-caseList {
      display: flex;
      flex-direction: column;
     align-items: flex-end;
      div:first-child{
        margin-top: 5px;
      }
      p {
        margin-left: 10px;
        font-size: 12px;
        zoom: 0.92;
        color: #333333;
        font-family: SourceHanSansCN-Medium;
        margin-top: 15px;
      }
      div:last-child {
        margin-bottom: 8px;
      }
    }
  }
  .miFooter {
    z-index: 0;
    position: relative !important;
    border: none;
    top: 0;
    padding-top: 21px;
    background-color: #1B2327;
    display: flex;
    flex-direction: column;
    align-items: center;
    .miFooter-top {
      display: flex;
      padding-bottom: 21px;
      .miFooter-top-item :last-child{
        border-right: none;
      }
      .miFooter-top-item {
        border-right: 1px solid #f5f9fb;
        padding-right: 14px;
        padding-left: 14px;
        p {
          width: 100%;
          color: #fff;
          font-family: SourceHanSansCN-Medium;
          font-size: 13px;
          white-space: nowrap;
        }
      }
    }
    .miFooter-line {
      width: 325px;
      height: 1px;
      background-color: rgba($color: #d4d4d4, $alpha: 0.2);
    }
    .miFooter-list {
      align-self: flex-start;
      margin-top: 21px;
      display: flex;
      flex-direction: column;
      padding-left: 25px;
      padding-bottom: 21px;
      a {
        color: #6D6D6D;
        font-size: 12px;
        margin-bottom: 10px;
        font-family: SourceHanSansCN-Medium;
      }
    }
  }
}
</style>